module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://172.16.120.128:2368","contentApiKey":"33016e31766ab420e73b009753"},"production":{"apiUrl":"http://172.16.120.128:2368","contentApiKey":"33016e31766ab420e73b009753"}},"siteConfig":{"siteUrl":"https://emronix.com","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Helping you Succeed In Your Journey To The Cloud","siteDescriptionMeta":"Turn your Ghost blog into a flaring fast static site with Gatsby","shortTitle":"Emronix","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/blog","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
